<template>
  <div>
    <v-select
      v-model="scriptId"
      :label="label"
      :items="scripts"
      item-value="id"
      item-text="nome"
      :outlined="type === 'outlined'"
      class="mb-0 pb-0"
      no-data-text="Nenhum script modelo disponível"
      :loading="loading"
    />
    <v-alert
      outlined
      type="info"
      color="blue"
      v-if="scriptId && script && script.descricao"
    >
      {{ script.descricao }}
    </v-alert>
  </div>
</template>

<script>
import ScriptsModelosService from '@/services/ScriptsModelosService';

export default {
  name: 'SelectScriptsModelos',
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    label: {
      type: String,
      default: 'Script Modelo'
    }
  },
  data() {
    return {
      scriptId: null,
      scripts: [],
      loading: false
    };
  },
  mounted() {
    this.getScriptsModelos();
  },
  computed: {
    script() {
      return this.scripts.find((script) => script.id === this.scriptId);
    }
  },
  methods: {
    getScriptsModelos() {
      this.loading = true;
      ScriptsModelosService.getScriptsAtivos()
        .then((response) => (this.scripts = response.data.data))
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    script() {
      this.$emit('scriptSelecionado', this.script);
    }
  }
};
</script>
