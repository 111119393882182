<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-file-code-outline"
            inline
            class="px-5 py-3"
            style="min-height: 600px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">Informações Básicas</div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-text-field
                  label="Título"
                  outlined
                  v-model="titulo"
                  :autofocus="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Descrição (opcional)"
                  outlined
                  v-model="descricao"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <select-company
                  :type="'outlined'"
                  :selectedCompany="userLoggedCompany"
                  @companySelected="
                    (companySelected) => (companyId = companySelected.id)
                  "
                />
              </v-col>
            </v-row>
            <v-row v-if="companyId">
              <v-col cols="12">
                <select-all-bdgd-versions
                  label="Versão da BDGD"
                  type="outlined"
                  :showIcon="false"
                  :companyId="companyId"
                  @bdgdVersionSelected="
                    (bdgdVersionSelected) => (bdgdVersion = bdgdVersionSelected)
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  min-width="100"
                  style="float: right"
                  class="mt-n5 mr-0"
                  @click="cadastrarAtualizacao()"
                  :disabled="!podeCadastrarAtualizacao"
                  :loading="loading"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-code-block-tags"
            inline
            class="px-5 py-3"
            style="min-height: 600px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">Script Modelo</div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <select-scripts-modelos
                  :type="'outlined'"
                  @scriptSelecionado="
                    (scriptSelecionado) => (script = scriptSelecionado)
                  "
                />
              </v-col>
            </v-row>
            <v-row v-if="templateSql">
              <v-col cols="12">
                <v-textarea
                  v-model="templateSql"
                  class="ta-padding-c"
                  rows="16"
                  no-resize
                  background-color="#EEEEEE"
                  :readonly="true"
                />
              </v-col>
            </v-row>
            <v-row v-if="templateSql">
              <v-col cols="12">
                <component
                  v-bind:is="formParametrosScript"
                  @parametrosScriptAtualizados="
                    (event) => (parametrosScript = event)
                  "
                />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import SelectCompany from '@/components/general/SelectCompany.vue';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions.vue';
import SelectScriptsModelos from '@/components/geracao-bdgd/atualizacoes-bdgd/scripts-modelos/SelectScriptsModelos.vue';
import CorrigeOPacDaUgbtComODaUcbt from '@/components/geracao-bdgd/atualizacoes-bdgd/scripts-modelos/parametros/CorrigeOPacDaUgbtComODaUcbt.vue';
import CorrigeOPacDoEqmeComODaUcbtUgbt from '@/components/geracao-bdgd/atualizacoes-bdgd/scripts-modelos/parametros/CorrigeOPacDoEqmeComODaUcbtUgbt.vue';
import RemoveCodIdRepetidos from '@/components/geracao-bdgd/atualizacoes-bdgd/scripts-modelos/parametros/RemoveCodIdRepetidos.vue';
import PropagacaoDaUniTrAtDaCtmt from '@/components/geracao-bdgd/atualizacoes-bdgd/scripts-modelos/parametros/PropagacaoDaUniTrAtDaCtmt.vue';
import AtualizacoesBdgdViaScriptModeloService from '@/services/AtualizacoesBdgdViaScriptModeloService';
import routes from '@/store/modules/routes';

export default {
  name: 'Add',
  components: {
    SelectCompany,
    SelectAllBdgdVersions,
    SelectScriptsModelos,
    CorrigeOPacDaUgbtComODaUcbt,
    CorrigeOPacDoEqmeComODaUcbtUgbt,
    RemoveCodIdRepetidos,
    PropagacaoDaUniTrAtDaCtmt
  },
  data() {
    return {
      titulo: null,
      descricao: null,
      script: null,
      companyId: null,
      bdgdVersion: null,
      parametrosScript: {},
      loading: false
    };
  },
  computed: {
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    templateSql() {
      return this.script ? this.script.templateSql : '';
    },
    formParametrosScript() {
      return this.script ? this.script.classe : null;
    },
    podeCadastrarAtualizacao() {
      return !!(
        this.titulo &&
        this.script &&
        this.companyId &&
        this.bdgdVersion
      );
    }
  },
  methods: {
    cadastrarAtualizacao() {
      this.loading = true;
      const atualizacao = {
        titulo: this.titulo,
        descricao: this.descricao,
        script_modelo_id: this.script.id,
        company_id: this.companyId,
        bdgd_versao_id: this.bdgdVersion.id,
        parametros_script_modelo: this.parametrosScript
      };
      AtualizacoesBdgdViaScriptModeloService.save(atualizacao)
        .then(() => {
          this.$toast.success('Atualização de BDGD salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD']
          });
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error('Erro ao salvar a atualização de BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style>
.ta-padding-c .v-text-field__slot {
  padding: 8px;
}
</style>
