<template>
  <v-row
    align="center"
    no-gutters
  >
    <v-col cols="12">
      <select-bdgd-entity
        :type="'outlined'"
        @entitySelected="entitySelected"
      />
    </v-col>
  </v-row>
</template>

<script>
import SelectBdgdEntity from '@/components/general/SelectBdgdEntity.vue';

export default {
  name: 'RemoveCodIdRepetidos',
  components: {
    SelectBdgdEntity
  },
  methods: {
    entitySelected(entitySelected) {
      this.$emit('parametrosScriptAtualizados', {
        entidade: entitySelected.toLowerCase()
      });
    }
  }
};
</script>
